import React, { useState, useEffect, useRef } from "react";
import {
  fetchQueryDocuments,
  fetchUniqueParties,
  fetchUniqueIndieners,
} from "../services/api";
import DocumentList from "../components/DocumentList"; // Adjust the import path as necessary
import SearchForm from "../components/SearchForm"; // Import the SearchForm component
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Link,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

// Cache to store documents, unique values, and search parameters between navigation
let cachedDocuments: any[] | null = null;
let cachedParties: string[] | null = null;
let cachedIndieners: string[] | null = null;
let cachedSearchParams: {
  searchText: string;
  minDate: Dayjs | null;
  maxDate: Dayjs | null;
  votedFor: string[];
  votedAgainst: string[];
  resultBoolean: boolean | undefined;
  indiener: string | null;
} | null = null;

const Homepage: React.FC = () => {
  const [documents, setDocuments] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [minDate, setMinDate] = useState<Dayjs | null>(null);
  const [maxDate, setMaxDate] = useState<Dayjs | null>(null);
  const [votedFor, setVotedFor] = useState<string[]>([]);
  const [votedAgainst, setVotedAgainst] = useState<string[]>([]);
  const [resultBoolean, setResultBoolean] = useState<boolean | undefined>(
    undefined
  );
  const [indiener, setIndiener] = useState<string | null>(null);
  const [parties, setParties] = useState<string[]>([]);
  const [indieners, setIndieners] = useState<string[]>([]);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  // Create a ref for the document list section
  const documentListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Load cached documents and search parameters if available
    if (cachedDocuments && cachedSearchParams) {
      setDocuments(cachedDocuments);
      setSearchText(cachedSearchParams.searchText);
      setMinDate(cachedSearchParams.minDate);
      setMaxDate(cachedSearchParams.maxDate);
      setVotedFor(cachedSearchParams.votedFor);
      setVotedAgainst(cachedSearchParams.votedAgainst);
      setResultBoolean(cachedSearchParams.resultBoolean);
      setIndiener(cachedSearchParams.indiener);
      setHasSearched(true);
      setLoading(false);
    } else {
      // Fetch latest documents if not cached
      fetchQueryDocuments({})
        .then((data) => {
          setDocuments(data);
          cachedDocuments = data; // Cache the documents
          setLoading(false);
        })
        .catch((err) => {
          console.error("Failed to fetch documents:", err);
          setError("Failed to fetch documents. Please try again later.");
          setLoading(false);
        });
    }

    // Check if unique parties are already cached
    if (cachedParties) {
      setParties(cachedParties);
    } else {
      // Fetch unique parties if not cached
      fetchUniqueParties()
        .then((data) => {
          setParties(data);
          cachedParties = data; // Cache the parties
        })
        .catch((err) => console.error("Failed to fetch parties:", err));
    }

    // Check if unique indieners are already cached
    if (cachedIndieners) {
      setIndieners(cachedIndieners);
    } else {
      // Fetch unique indieners if not cached
      fetchUniqueIndieners()
        .then((data) => {
          setIndieners(data);
          cachedIndieners = data; // Cache the indieners
        })
        .catch((err) => console.error("Failed to fetch indieners:", err));
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    try {
      const queryParams = {
        text: searchText,
        min_date: minDate ? minDate.format("YYYY-MM-DD") : undefined,
        max_date: maxDate ? maxDate.format("YYYY-MM-DD") : undefined,
        voted_for: votedFor.length ? votedFor : undefined,
        voted_against: votedAgainst.length ? votedAgainst : undefined,
        result_boolean: resultBoolean !== undefined ? resultBoolean : undefined,
        indiener: indiener ? indiener : undefined,
      };
      const data = await fetchQueryDocuments(queryParams);
      setDocuments(data);
      cachedDocuments = data; // Cache the documents
      cachedSearchParams = {
        searchText,
        minDate,
        maxDate,
        votedFor,
        votedAgainst,
        resultBoolean,
        indiener,
      }; // Cache search parameters
      setHasSearched(true);
      setLoading(false);

      // Scroll down to the document list section after the search is complete
      if (documentListRef.current) {
        documentListRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (err) {
      console.error("Failed to fetch documents:", err);
      setError("Failed to fetch documents. Please try again later.");
      setLoading(false);
    }
  };

  // Trigger search on Enter key press
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  // Determine the title for DocumentList based on whether a search has been performed
  const documentListTitle = hasSearched
    ? "Moties op basis van uw zoekopdracht"
    : "Meest recente moties";

  return (
    <>
      <Box
        sx={{
          backgroundImage: "url(/images/tweedekamer-transformed3.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat", // Ensure the image does not repeat
          width: "100vw",  // Make sure the width is 100% of the viewport
          minHeight: "100vh",  // Ensure it covers the height of the viewport as well
          paddingBottom: 10,
          paddingTop: 5,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          position: "relative",
          overflow: "hidden",  // To ensure no content exceeds the boundaries
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            color: "white",
          }}
        >
          <Link href="/about" underline="hover" style={{ color: "white" }}>
            Meer informatie
          </Link>
        </Box>

        <Box
          mb={4}
          sx={{
            textAlign: "center",
            maxWidth: "90%",
            margin: "0 auto",
            color: "white",
            padding: 2,
            "@media (min-width:600px)": {
              maxWidth: "60%",
            },
            "@media (min-width:960px)": {
              maxWidth: "40%",
            },
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            sx={{ fontWeight: "bold", mb: 4 }}
          >
            <img
              src="/images/ikzoekeenmotie_transparant.png"
              alt="Ikzoekeenmotie.nl"
              style={{ width: "50%", maxWidth: "250px", height: "auto" }}
            />
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Welkom op deze website, een tool om inzicht te krijgen in moties uit
            de Tweede Kamer waarop gestemd is. De website is een aanvulling op
            de officiële site,
            <Link
              href="https://tweedekamer.nl"
              underline="hover"
              style={{ marginLeft: "5px", color: "white" }}
            >
              tweedekamer.nl
            </Link>
            , en maakt het eenvoudiger om gericht moties te kunnen vinden. Met
            diverse filtermogelijkheden, zoekfunctie op context, en
            AI-gegenereerde samenvattingen, is in één oogopslag te zien waar een
            motie over gaat. Let op: de samenvattingen kunnen fouten bevatten.
            Begin hieronder met zoeken!
          </Typography>
        </Box>
        <Box>
          <SearchForm
            searchText={searchText}
            setSearchText={setSearchText}
            minDate={minDate}
            setMinDate={setMinDate}
            maxDate={maxDate}
            setMaxDate={setMaxDate}
            votedFor={votedFor}
            setVotedFor={setVotedFor}
            votedAgainst={votedAgainst}
            setVotedAgainst={setVotedAgainst}
            resultBoolean={resultBoolean}
            setResultBoolean={setResultBoolean}
            indiener={indiener}
            setIndiener={setIndiener}
            parties={parties}
            indieners={indieners}
            handleSearch={handleSearch}
            handleKeyDown={handleKeyDown}
          />
        </Box>
      </Box>
      <Container>
        <Box
          sx={{
            maxWidth: "900px",
            margin: "auto",
            marginTop: 5,
          }}
          ref={documentListRef} // Reference to scroll into view
        >
          {/* Document List */}
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <DocumentList documents={documents} title={documentListTitle} />
          )}
        </Box>
      </Container>
    </>
  );
};

export default Homepage;
