import React, { useState } from "react";
import {
  TextField,
  Button,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Paper,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

interface SearchFormProps {
  searchText: string;
  setSearchText: (value: string) => void;
  minDate: Dayjs | null;
  setMinDate: (value: Dayjs | null) => void;
  maxDate: Dayjs | null;
  setMaxDate: (value: Dayjs | null) => void;
  votedFor: string[];
  setVotedFor: (value: string[]) => void;
  votedAgainst: string[];
  setVotedAgainst: (value: string[]) => void;
  resultBoolean: boolean | undefined;
  setResultBoolean: (value: boolean | undefined) => void;
  indiener: string | null;
  setIndiener: (value: string | null) => void;
  parties: string[];
  indieners: string[];
  handleSearch: () => void;
  handleKeyDown: (event: React.KeyboardEvent) => void;
}

const SearchForm: React.FC<SearchFormProps> = ({
  searchText,
  setSearchText,
  minDate,
  setMinDate,
  maxDate,
  setMaxDate,
  votedFor,
  setVotedFor,
  votedAgainst,
  setVotedAgainst,
  resultBoolean,
  setResultBoolean,
  indiener,
  setIndiener,
  parties,
  indieners,
  handleSearch,
  handleKeyDown,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Paper
      sx={{
        padding: 4,
        boxShadow: 100,
        margin: "0 auto",
        width: "100%",
        maxWidth: { xs: "90%", sm: "80%", md: "500px" }, 
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          variant="outlined"
          fullWidth
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsFocused(true)} // Set focus state to true when focused
          onBlur={() => setIsFocused(false)} // Set focus state to false when blurred
          InputLabelProps={{
            style: {
              color: "#888", // Customize label color if needed
            },
          }}
          label="Zoekopdracht" // Static label text
        />

        {/* Date Pickers */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Datum vanaf"
              value={minDate}
              onChange={(newValue) => setMinDate(newValue)}
              format="DD/MM/YYYY"
              slotProps={{
                textField: { fullWidth: true },
                actionBar: { actions: ["clear"] },
              }}
            />
            <DatePicker
              label="Datum tot"
              value={maxDate}
              onChange={(newValue) => setMaxDate(newValue)}
              format="DD/MM/YYYY"
              slotProps={{
                textField: { fullWidth: true },
                actionBar: { actions: ["clear"] },
              }}
            />
          </LocalizationProvider>
        </Box>

        {/* Voted For Autocomplete */}
        <Autocomplete
          multiple
          options={parties}
          getOptionLabel={(option) => option}
          value={votedFor}
          onChange={(event, newValue) => setVotedFor(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Fracties die voor hebben gestemd"
              placeholder="Select Parties"
            />
          )}
        />

        {/* Voted Against Autocomplete */}
        <Autocomplete
          multiple
          options={parties}
          getOptionLabel={(option) => option}
          value={votedAgainst}
          onChange={(event, newValue) => setVotedAgainst(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Fracties die tegen hebben gestemd"
              placeholder="Select Parties"
            />
          )}
        />

        {/* Indiener Autocomplete */}
        <Autocomplete
          options={indieners}
          getOptionLabel={(option) => option}
          value={indiener}
          onChange={(event, newValue) => setIndiener(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Indiener van de motie"
              placeholder="Select Indiener"
            />
          )}
          style={{ marginTop: 10 }}
        />

        {/* Result Boolean Select */}
        <FormControl variant="outlined" fullWidth>
          <InputLabel></InputLabel>
          <Select
            value={
              resultBoolean === undefined
                ? "Beide uitkomsten"
                : resultBoolean
                ? "Aangenomen"
                : "Verworpen"
            }
            onChange={(event) => {
              const value = event.target.value;
              if (value === "Aangenomen") {
                setResultBoolean(true);
              } else if (value === "Verworpen") {
                setResultBoolean(false);
              } else {
                setResultBoolean(undefined);
              }
            }}
          >
            <MenuItem value="Beide uitkomsten">Beide uitkomsten</MenuItem>
            <MenuItem value="Aangenomen">Aangenomen</MenuItem>
            <MenuItem value="Verworpen">Verworpen</MenuItem>
          </Select>
        </FormControl>

        {/* Search Button */}
        <Button variant="contained" color="primary" onClick={handleSearch} fullWidth>
          Zoeken
        </Button>
      </Box>
    </Paper>
  );
};

export default SearchForm;
