// src/services/api.ts
import axios from 'axios';

const API_URL = 'https://ikzoekeenmotie.nl/api';

interface PartyResponse {
  unique_parties: string[];
}

interface IndienerResponse {
  unique_indieners: string[];
}

interface DocumentResponse {
  document: any;
}

interface QueryDocumentsResponse {
  documents: any[];
}

interface ExampleQueriesResponse {
  example_queries: string[];
}

interface QueryParams {
  text?: string;
  min_date?: string;
  max_date?: string;
  voted_for?: string[];
  voted_against?: string[];
  result_boolean?: boolean;
  indiener?: string;
}

const handleApiError = (error: any) => {
  console.error('API Error:', error);
  throw error;
};

export const fetchUniqueParties = async (): Promise<string[]> => {
  try {
    const response = await axios.get<PartyResponse>(`${API_URL}/unique-parties`);
    return response.data.unique_parties;
  } catch (error) {
    handleApiError(error);
    return [];
  }
};

export const fetchUniqueIndieners = async (): Promise<string[]> => {
  try {
    const response = await axios.get<IndienerResponse>(`${API_URL}/unique-indieners`);
    return response.data.unique_indieners;
  } catch (error) {
    handleApiError(error);
    return [];
  }
};

export const fetchSingleDocument = async (documentId: string): Promise<any> => {
  try {
    const response = await axios.get<DocumentResponse>(`${API_URL}/document/${documentId}`);
    return response.data.document;
  } catch (error) {
    handleApiError(error);
    return null;
  }
};

export const fetchQueryDocuments = async (queryData: QueryParams): Promise<any[]> => {
  try {
    const response = await axios.post<QueryDocumentsResponse>(`${API_URL}/query-documents`, queryData);
    return response.data.documents;
  } catch (error) {
    handleApiError(error);
    return [];
  }
};

export const fetchSimilarDocuments = async (documentId: string): Promise<any[]> => {
  try {
    const response = await axios.get<QueryDocumentsResponse>(`${API_URL}/query-similar-documents`, {
      params: { document_nummer: documentId },
    });
    return response.data.documents;
  } catch (error) {
    handleApiError(error);
    return [];
  }
};

// New function for fetching example queries (text lines from the /example-queries endpoint)
export const fetchExampleQueries = async (): Promise<string[]> => {
  try {
    const response = await axios.get<ExampleQueriesResponse>(`${API_URL}/example-queries`);
    return response.data.example_queries;
  } catch (error) {
    handleApiError(error);
    return [];
  }
};
